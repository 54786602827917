<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Invoices'">
      <template v-slot:right-end>
        <div class="vx-row my-5 mx-3 flex" style="place-items: end;">

          <!--          <div class="vx-col">-->

          <!--            <vs-button-->
          <!--              v-if="activeUserInfo.account_role < 2"-->
          <!--              @click="openAddInvoice"-->
          <!--              type="filled" icon-pack="feather"-->
          <!--              icon="icon-plus">{{ $t('GenerateInvoice') }}-->
          <!--            </vs-button>-->
          <!--          </div>-->
        </div>
      </template>
    </breadcrumb-base>

    <!-- Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="vx-row">
        <div class=" w-full vx-col md:w-1/5 no-print">
          <vx-card>
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('FromDate')" v-model="fromDate"
                        :disabled-dates="{from: new Date()}"
                        v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('ToDate')" v-model="toDate"
                        :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>

            <div class="py-4"></div>
            <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{ $t('Filter').toUpperCase() }}
            </vs-button>
            <div class="py-2"></div>
            <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}
            </vs-button>
          </vx-card>
        </div>
        <div class="w-full md:w-4/5">
          <!-- Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="invoices.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>

          <vs-table-modified v-show="invoices.length > 0 && !errorFetching" ref="table"
                             :max-items="itemsPerPage"
                             :data="invoices">
            <template slot="thead">
              <vs-th>{{ $t('InvoiceDate') }}</vs-th>
              <vs-th>{{ $t('CustomerID') }}</vs-th>
              <vs-th>{{ $t('Organisation') }}</vs-th>
              <vs-th>{{ $t('InvoiceNo') }}</vs-th>
              <vs-th>{{ $t('CreditNoteNo') }}</vs-th>
              <vs-th>{{ $t('InvoiceValue') }}</vs-th>
              <vs-th>{{ $t('CreditNoteValue') }}</vs-th>
              <vs-th>{{ $t('Tax') }}</vs-th>
              <vs-th>{{ $t('Action') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="user-name font-medium truncate"
                     style="max-width: 100px;white-space: normal !important;padding: 0 8px;"
                  >{{ tr.invoice_date | onlyDateFormat }}</p>
                </vs-td>
                <vs-td>
                  <div class="vs-col">
                    <p class="user-name font-medium truncate">{{ tr.customer_id }}</p>
                    <p class="product-name font-medium truncate">
                      {{ tr.organisation_name | capitalize }}</p>
                  </div>
                </vs-td>

                <vs-td>
                  <div class="vs-col">
                    <p class="truncate">{{ tr.organisation_first_name }}
                      {{ tr.organisation_last_name }}</p>
                    <p style="font-size: 11px;">
                      {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                    </p>
                    <p style="font-size: 11px;">
                      {{ tr.postal }} {{ tr.city }}
                    </p>
                  </div>
                </vs-td>


                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.invoice_no }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.credit_note_no }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{
                      tr.invoice_value | germanNumberFormat
                    }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">
                    {{ tr.credit_note_value | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.tax }}</p>
                </vs-td>

                <vs-td class="text-bold text-center text-white" style="width: 150px;">

                  <div class="vs-col">
                    <a
                      v-if="tr.invoice_pdf"
                      class="bg-primary px-4 py-2 text-white visited:text-white hover:text-white cursor-pointer rounded"
                      :href="tr.invoice_pdf" :download="tr.invoice_file_name">
                      {{ $t("Download") }}
                    </a>
                    <template v-else>
                      <div class="bg-primary px-3 py-2 rounded" @click="openInvoice(1, tr.invoice_id)">{{ $t('Gross') }}</div>
                      <div class="py-2"></div>
                      <div class="bg-primary px-3 py-2 rounded" @click="openInvoice(2, tr.invoice_id)">{{ $t('CreditNote') }}</div>
                    </template>

                  </div>
                  <!--                  <vs-button class="" @click="openInvoice(1, tr.invoice_id)">{{ $t('Gross') }}</vs-button>-->
                  <!--              <div class="px-2"></div>-->
                  <!--                  <vs-button class="ml-2" @click="openInvoice(2, tr.invoice_id)">{{ $t('CreditNote') }}-->
                  <!--                  </vs-button>-->
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage"
                                   v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import moment from "moment";
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'ViewDevices',
  components: {
    DynamicCustomersDropDown,
    Datepicker
  },
  data() {
    return {
      cPage: 1,
      itemsPerPage: 20,
      isMounted: false,
      fromDate: null,
      toDate: null,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Invoices', i18n: 'Invoices', active: true},
      ],
    }
  },
  computed: {
    invoices() {
      return this.$store.state.invoice.data
    },
    vPage() {
      return this.$store.state.invoice.vPage
    },
    vTotalPages() {
      return this.$store.state.invoice.vTotalPages
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    disabledDates() {
      if (!this.fromDate) {
        return {
          from: new Date(),
        }
      }
      return {
        to: this.fromDate,
        from: new Date(),
      }
    },
  },
  methods: {
    openInvoice(type, id) {
      if (type == 1) {
        this.$router.push({
          name: 'new-invoice-gross-view', params: {
            invoiceId: id
          }
        })
      } else {
        this.$router.push({
          name: 'new-invoice-credit-note-view', params: {
            invoiceId: id
          }
        })
      }
    },
    onCustomerChanged(c) {
      this.customer = c
      this.fetchInvoices()
    },
    openAddInvoice() {
      this.$router.push({name: 'invoice-generate'})
    },

    clearFilterData() {
      this.fromDate = null
      this.toDate = null
      this.filterChanged()
    },
    getFilters() {
      const filter = {}

      if (this.fromDate) {
        filter.from_date = moment(this.fromDate).format('yyyy-MM-DD')
      }
      if (this.toDate) {
        filter.to_date = moment(this.toDate).format('yyyy-MM-DD')
      }
      return filter
    },
    filterChanged() {
      this.fetchInvoices('0')
    },
    listPageChanged(item) {
      this.fetchInvoices(this.cPage - 1)
    },
    fetchInvoices(page = '0', filters = {}) {
      const payload = {
        page: page,
        my_invoice: 1,
        ...this.getFilters(),
      }
      this.$vs.loading()
      return this.$store.dispatch('invoice/fetchInvoices', {payload})
        .then((data) => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchInvoices()
  },
}
</script>

<style scoped>

</style>
